// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~jquery-ui/themes/base/all.css';
// Variables
@import 'variables';

$dark: #1b5691;
$navbar-dark-hover-color: #fff;
$navbar-dark-active-color: #fff;
// Bootstrap
@import '~bootstrap/scss/bootstrap';

.container.wide {
    max-width: 1600px;
}

@media screen and (max-width: 769px) {
    table thead {
        display: none;
    }

    table td {
        display: flex;
    }

    table td::before {
        content: attr(label);
        font-weight: bold;
        width: 120px;
        min-width: 120px;
    }
}
